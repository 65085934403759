import { UserInfo } from './header/UserInfo';
import { ChangeLanguage } from './header/ChangeLanguage';
import { LogoutButton } from './header/LogoutButton';
import { SvgComponent } from './svgComponent/SvgComponent';

export const Header = ({ setSidebarOpen, sidebarTrigger }) => {
    return (
        <header className="sticky top-0 bg-white border-b border-gray-10 z-30">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center h-16 -mb-px">
                    <div className="flex">
                        <button className="text-gray-20 hover:text-slate-600 lg:hidden" onClick={() => setSidebarOpen(true)} ref={sidebarTrigger}>
                            <span className="sr-only">Open sidebar</span>
                            <SvgComponent name="burger" />
                        </button>
                    </div>

                    <div className="flex items-center gap-2 ml-auto h-full py-4">
                        <UserInfo />
                        <ChangeLanguage />
                        <div className="w-px h-full bg-gray-10 mx-4"></div>
                        <LogoutButton />
                    </div>
                </div>
            </div>
        </header>
    );
};
