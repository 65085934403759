import { useSelector } from 'react-redux';

import { name as nameState } from '../../../redux/slices/loginSlice';

import { SvgComponent } from '../svgComponent/SvgComponent';

export const UserInfo = () => {
    const name = useSelector(nameState);

    return (
        <div className="text-gray-20 flex items-center gap-1">
            <SvgComponent name="user" />
            <div className="font-medium text-sm">{name}</div>
        </div>
    );
};
