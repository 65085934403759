import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';

import { setLanguageFetch } from '../../../redux/slices/loginSlice';
import { listenForOutsideClick } from '../../../api/functions';

import { SvgComponent } from '../svgComponent/SvgComponent';

export const ChangeLanguage = () => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies();
    const dispatch = useDispatch();

    const menuLangRef = useRef(null);

    const [langMenu, setLangMenu] = useState(false);
    const [listening, setListening] = useState(false);

    useEffect(listenForOutsideClick(listening, setListening, menuLangRef, setLangMenu));

    const changeLanguage = lang => {
        setLangMenu(false);
        i18n.changeLanguage(lang).then(() => {
            dispatch(setLanguageFetch({ lang }));
            setCookie('i18next', lang, { path: '/' });
        });
    };

    return (
        <div className="relative flex items-center gap-2 ml-8 text-gray-20" ref={menuLangRef}>
            <button type="button" className="flex gap-2 items-center justify-start" onClick={() => setLangMenu(!langMenu)}>
                <div className="shrink-0">
                    <SvgComponent name="globe" />
                </div>

                <div className="text-base font-medium uppercase w-5">{cookies.i18next}</div>

                <div className="w-4 h-4 shrink-0">
                    <SvgComponent name="chevron-down" />
                </div>
            </button>

            {langMenu && (
                <ul className="absolute right-0 top-10 bg-white w-32 px-3 py-2 shadow-3xl space-y-4 text-gray-50 text-sm">
                    <li className={`relative hover:text-blue cursor-pointer ${cookies.i18next === 'ru' && 'text-blue'}`}>
                        <button className="flex items-center justify-between w-full" type="button" onClick={() => changeLanguage('ru')}>
                            {t('LANGUAGE_RU')}
                            {cookies.i18next === 'ru' && <SvgComponent name="check" />}
                        </button>
                    </li>

                    <li className={`relative hover:text-blue cursor-pointer ${cookies.i18next === 'en' && 'text-blue'}`}>
                        <button className="flex items-center justify-between w-full" type="button" onClick={() => changeLanguage('en')}>
                            {t('LANGUAGE_EN')}
                            {cookies.i18next === 'en' && <SvgComponent name="check" />}
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};
